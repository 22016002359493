@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .inputField {
    @apply w-full bg-white py-3 px-4 rounded-md text-black font-medium text-base placeholder:font-normal placeholder:text-gray-600 outline-none border border-transparent
    /* @apply w-full bg-transparent border-b-[1px] border-b-gray-700 text-base font-medium placeholder:text-gray-500 text-gray-200 py-2 px-4 outline-none hover:border-b-reactYellowLight focus-within:border-b-reactYellow duration-200 tracking-wide; */;
  }
  .bannerInputField {
    @apply w-full bg-transparent border-b-[1px] border-b-gray-700 text-base font-medium placeholder:text-gray-500 text-gray-200 py-2 px-4 outline-none hover:border-b-reactYellowLight focus-within:border-b-reactYellow duration-200 tracking-wide;
  }
  .contactInput {
    @apply w-full text-base text-zinc-200 placeholder:text-zinc-400 border-b-[1px] border border-transparent border-b-reactYellow bg-zinc-800 py-3 px-2 outline-none focus-within:border-[1px] focus-within:border-reactYellow duration-100;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.active {
  color: #ffc300;
}
